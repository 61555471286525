import $ from 'jquery';

$(document).ready(function () {
  step15();
});

function step15() {
  let docusignForm = $('#docusign-form');
  let step15Link = $('#step-15-submit').attr('href');
  let mcCheckbox = $('#mccheckbox');
  let tpaCheckbox = $('#tpacheckbox');
  let wcCheckbox = $('#wccheckbox');
  let email1 = $('.email-1');
  let email2 = $('.email-2');
  let email3 = $('.email-3').text();
  let email4 = $('.email-4').text();
  let email5 = $('.email-5').text();
  let email6 = $('.email-6');

  $('#step-15-submit').on('click', function () {
    console.log('Redirect to profile');
  });

  $('.second-label').on('click', function () {
    let parent = $(this).parents('.docusign-form__item');
    let item = parent.find('.second-label');
    item.addClass('info-show');
    let text = parent.next();
    text.slideDown();
  });

  $('.first-label').on('click', function () {
    let parent = $(this).parents('.docusign-form__item');
    let item = parent.find('input');
    item.prop('checked', true);
  });

  $('.i-text__close').on('click', function () {
    let parent = $(this).parent('.i-text');
    parent.slideUp();
  });

  $('.selection-buttons span').on('click', function () {
    $('.directors-and-officers-info').hide();
    $('.directors-and-officers-form-wrap').show();
  });

  function isValidEmail(email) {
    // Regular expression for email validation
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  let emails = [email1, email2, email6];

  var isValid = true;

  for (var i = 0; i < emails.length; i++) {
    if (!isValidEmail(emails[i])) {
      isValid = false;
      break;
    }
  }

  $('.step-15-next').on('click', function (el) {
    el.preventDefault();
    console.log('plus');
    $(this).off('click');
    $(this).css('pointer-events', 'none');

    function sendEnvelopes() {
      $('.preloader-gif').addClass('preloader-gif-active');

      let imageSrc = $(this).find('img').attr('src');
      $(this).html(
        ' Submitting <img class="preloader-gif preloader-gif-active" src=' +
        imageSrc +
        '>'
      );

      let signersArr = [];
      const signersBlocks = document.querySelectorAll(
        '.member-signatories-item'
      );

      signersBlocks.forEach((block, index) => {
        const mailId = block
          .querySelector('.member-signatories-item__title')
          .getAttribute('data-mail');
        const role = block
          .querySelector('.member-signatories-item__title')
          .getAttribute('data-role');
        const nameElement = block.querySelector(
          '.member-signatories-item__name'
        );
        const addressElement = block.querySelector(
          '.member-signatories-item__email'
        );

        const name = nameElement.innerText.trim();
        const address = addressElement.innerText.trim();

        if (name !== '' && address !== '') {
          const dataObject = {
            mailId: mailId,
            name: name,
            address: address,
            role: role,
          };

          signersArr.push(dataObject);
        }
      });
      console.log(signersArr);

      signersArr = JSON.stringify(signersArr);

      var formData = new FormData();
      formData.append('action', 'send_to_signers');
      formData.append('signers_info', signersArr);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => docusignForm.submit())
        .catch((err) => console.log(err));
    }

    if (tpaCheckbox.length) {
      if (
        mcCheckbox.is(':checked') &&
        tpaCheckbox.is(':checked') &&
        wcCheckbox.is(':checked') &&
        email1.length &&
        email6.length
      ) {
        sendEnvelopes();
      } else {
        mcCheckbox.next('.label-hidden').css('border', '#ff0766 solid 2px');
        tpaCheckbox.next('.label-hidden').css('border', '#ff0766 solid 2px');
        wcCheckbox.next('.label-hidden').css('border', '#ff0766 solid 2px');
        $('html, body').animate({scrollTop: 0}, 800);
      }
    } else {
      if (
        mcCheckbox.is(':checked') &&
        wcCheckbox.is(':checked') &&
        email1.length &&
        email6.length
      ) {
        sendEnvelopes();
      } else {
        mcCheckbox.next('.label-hidden').css('border', '#ff0766 solid 2px');
        tpaCheckbox.next('.label-hidden').css('border', '#ff0766 solid 2px');
        wcCheckbox.next('.label-hidden').css('border', '#ff0766 solid 2px');
        $('html, body').animate({scrollTop: 0}, 800);
      }
    }
  });

  //comment submit
  var rejectFormButton = $('#step-15-reject-form');
  var commentForm = $('#comment-15-step-form');

  $('#step-15-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });
  $('.member-signatories-item__status').each(function () {
    var dataMailValue = $(this).attr('data-staus');

    if (dataMailValue === 'completed') {
      $(this).text('Received');
    } else if (dataMailValue === 'autoresponded') {
      $(this).text('Received');
    } else {
      $(this).text('Pending');
    }
  });
}
