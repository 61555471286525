// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import 'regenerator-runtime/runtime';

// import jsPDF from 'jspdf';

import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
import '../blocks/gutenberg/acf-button-block/index.js'; import '../blocks/gutenberg/acf-downloads-block/index.js'; import '../blocks/gutenberg/acf-example-block/index.js'; import '../blocks/gutenberg/acf-faq-block/index.js'; import '../blocks/gutenberg/acf-hero-section-block/index.js'; import '../blocks/gutenberg/acf-meeting-schedule-block/index.js'; import '../blocks/gutenberg/acf-standart-text-block/index.js'; import '../blocks/gutenberg/acf-two-column-text-block/index.js'; import '../blocks/gutenberg/acf-two-thirds-block/index.js';

/**
 * Import scripts from Form Templates
 */
// eslint-disable-next-line import/no-unresolved
import '../../templates/profile/views/steps/1/index.js'; import '../../templates/profile/views/steps/10/index.js'; import '../../templates/profile/views/steps/11/index.js'; import '../../templates/profile/views/steps/12/index.js'; import '../../templates/profile/views/steps/13/index.js'; import '../../templates/profile/views/steps/14/index.js'; import '../../templates/profile/views/steps/15/index.js'; import '../../templates/profile/views/steps/2/index.js'; import '../../templates/profile/views/steps/3/index.js'; import '../../templates/profile/views/steps/4/index.js'; import '../../templates/profile/views/steps/5/index.js'; import '../../templates/profile/views/steps/6/index.js'; import '../../templates/profile/views/steps/7/index.js'; import '../../templates/profile/views/steps/8/index.js'; import '../../templates/profile/views/steps/9/index.js';
import $ from 'jquery';

/* global ajax_object */
/* global custom_vars */
/* global html2pdf */

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
  $('.menu-icon').click(function () {
    if ($('.login-button__wrap').hasClass('active-button')) {
      $('.login-button__wrap').removeClass('active-button');
    } else {
      $('.login-button__wrap').addClass('active-button');
    }
  });
  $('.info-buttonn').on('click', function () {
    $('.info-textt').slideDown();
  });

  // custom checkbox
  $('.first-label').on('click', function () {
    let parent = $(this).parent('.form-checkbox');
    let box = parent.find('input');
    let label = parent.find('label.label-hidden');
    let edit = parent.find('label.second-label');
    box.removeClass('center');
    if (edit.hasClass('active-label')) {
      edit.removeClass('active-label');
    }
    if (label.hasClass('edit')) {
      label.removeClass('edit');
    }
    label.toggleClass('confirm');
    $(this).addClass('active-label');
  });
  $('.second-label').on('click', function () {
    let parent = $(this).parent('.form-checkbox');
    let box = parent.find('input');
    let label = parent.find('label.label-hidden');
    let confirm = parent.find('label.first-label');
    box.removeClass('center');
    if (confirm.hasClass('active-label')) {
      confirm.removeClass('active-label');
    }
    if (label.hasClass('confirm')) {
      label.removeClass('confirm');
    }
    label.toggleClass('edit');
    $(this).addClass('active-label');
  });

  $('.new-user-save').on('click', function (el) {
    el.preventDefault();
    let contactEditForm = $('#step-1-edit-form');
    contactEditForm.submit();
    let editLink = $(this).attr('href');

    setTimeout(function () {
      window.location.href = editLink;
    }, 300);
  });

  let ContactFileBtn = $('#primary_contact_information-form .hidden-file');
  let ContactCustomBtn = $('#primary_contact_information-form .custom-button');

  ContactCustomBtn.on('click', function () {
    ContactFileBtn.click();
  });
  ContactFileBtn.on('change', function () {
    let file = $('#primary_contact_information')[0].files[0];
    var formData = new FormData();
    formData.append('action', 'profile_file_contact');
    formData.append('primary_contact_information', file);

    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .catch((err) => console.log(err));

    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  let realFileBtn = $('#audited-financial-file-form .hidden-file');
  let customBtn = $('#audited-financial-file-form .custom-button');

  customBtn.on('click', function () {
    realFileBtn.click();
  });
  realFileBtn.on('change', function () {
    let file = $('#audited-financial-file')[0].files[0];
    var formData = new FormData();
    formData.append('action', 'profile_file');
    formData.append('audited-financial-file', file);

    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .catch((err) => console.log(err));

    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  // Most recent excess policy
  let excessPolicyFileBtn = $('#most_recent_excess_policy-form .hidden-file');
  let excessPolicyBtn = $('#most_recent_excess_policy-form .custom-button');

  excessPolicyBtn.on('click', function () {
    excessPolicyFileBtn.click();
  });
  excessPolicyFileBtn.on('change', function () {
    let file = $('#most_recent_excess_policy')[0].files[0];
    let formData = new FormData();
    formData.append('action', 'most_recent_excess_policy');
    formData.append('most_recent_excess_policy-file', file);

    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .catch((err) => console.log(err));

    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  // Financial Information
  let financialInformationFileBtn = $(
    '#company_financial_information-form .hidden-file'
  );
  let financialInformationBtn = $(
    '#company_financial_information-form .custom-button'
  );

  financialInformationBtn.on('click', function () {
    financialInformationFileBtn.click();
  });
  financialInformationFileBtn.on('change', function () {
    let file = $('#company_financial_information')[0].files[0];
    var formData = new FormData();
    formData.append('action', 'financial_information');
    formData.append('financial_information_file', file);

    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .catch((err) => console.log(err));
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  //global ajax_object
  //admin post filter
  $('.status-filter-tab').on('click', function () {
    $('.status-filter-tab').removeClass('active-tab');
    $(this).addClass('active-tab');
    var selectedValue = $(this).attr('data-status');
    let label = document.querySelector('.year-dropdown__filter-selected');
    let year = label.textContent;

    $.ajax({
      // eslint-disable-next-line no-unused-vars
      url: ajax_object.ajax_url, // WordPress AJAX URL
      type: 'POST',
      data: {
        action: 'filter_status',
        form_status: selectedValue,
        year: year,
      },
      success: function (response) {
        $('#js-form-post-list').html(response); // Update the post container with the filtered posts
      },
    });
  });

  let dropdown = $('.year-dropdown');
  if (dropdown.length) {
    let label = document.querySelector('.year-dropdown__filter-selected');
    let options = Array.from(
      document.querySelectorAll('.year-dropdown__select-option')
    );

    options.forEach((option) => {
      option.addEventListener('click', () => {
        label.textContent = option.textContent;
        let year = option.dataset.year;

        $.ajax({
          type: 'POST',
          url: ajax_object.ajax_url,
          data: {
            action: 'filter_years_posts',
            year: year,
          },
          success: function (response) {
            $('#js-form-post-list').html(response);
          },
        });
      });
    });

    // Close dropdown onclick outside
    document.addEventListener('click', (e) => {
      const toggle = document.querySelector('.year-dropdown__switch');
      const element = e.target;

      if (element == toggle) return;

      const isDropdownChild = element.closest('.year-dropdown__filter');

      if (!isDropdownChild) {
        toggle.checked = false;
      }
    });
  }

  // disable form when its submitted
  let formStatus = custom_vars.form_status;
  let currrentStepForms = $('.form-wrap__content');

  if (formStatus === 'submitted' || formStatus === 'resubmited') {
    let allForm = $('form', currrentStepForms);

    allForm.each(function () {
      $(this).prop('inert', true);
    });
  }

  $('#loginform').removeAttr('inert');
  $('#form-all-submit').removeAttr('inert');

  //form data download
  $('#download-data').on('click', function (e) {
    e.preventDefault();
    const selectedPosts = [];
    $('input[name="selected_posts[]"]:checked').each(function () {
      selectedPosts.push($(this).val());
    });
    let selectedJson = JSON.stringify(selectedPosts);

    let formData = new FormData();
    formData.append('action', 'generate_xlsx');
    formData.append('selected_posts', selectedJson);

    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: formData,
      processData: false,
      contentType: false,
      success: function (response) {
        if (response.success) {
          var downloadLink = document.createElement('a');
          downloadLink.href = response.data.file_url;
          downloadLink.download = response.data.filename;
          downloadLink.click();
        } else {
          console.error('error');
        }
      },
    });
  });

  $('.form-post-list__item').on('click', () => {
    let formLink = $(this).find('.form-post-list__item-company a').attr('href');
    window.location.href = formLink;
  });

  $('#form-download-button').on('click', function (e) {
    e.preventDefault();
    $('#download-data').trigger('click');
    $('.specific-company-form').fadeOut();
    $('input[name="selected_posts[]"]:checked').prop('checked', false);
  });
  $('#download-specific-data').on('click', function (e) {
    e.preventDefault();
    $('.specific-company-form').fadeIn();
  });
  $('.specific-company-form .close-btn').on('click', function () {
    $('.specific-company-form').fadeOut();
    $('input[name="selected_posts[]"]:checked').prop('checked', false);
  });

  //create new user
  var $newRowTemplate = $('.new-user-template .new-user-item').clone();
  let newUserForm = $('#create-new-user-form');

  $('#add_new').on('click', function () {
    var $newRow = $newRowTemplate.clone().removeAttr('id');
    newUserForm.append($newRow);
  });

  $('#save_user').on('click', function () {
    let formArray = [];

    let userForm = document.getElementById('create-new-user-form');
    let userFields = userForm.querySelectorAll('.new-user-item');

    userFields.forEach(function (item) {
      let formItem = {};
      let fields = item.querySelectorAll('input');
      fields.forEach(function (field) {
        formItem[field.name] = field.value;
      });
      formArray.push(formItem);
    });

    formArray = JSON.stringify(formArray);
    var formData = new FormData();
    formData.append('action', 'new_user');
    formData.append('new_user_data', formArray);

    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // Redirect to profile page on successful user creation
          window.location.href = '/profile';
        } else {
          // Handle error message
          if (data.message === 'User already exists') {
            // Redirect to a specific URL including the company parameter
            window.location.href = `/settings`;
          } else {
            // Handle other error messages
            // console.log(data.message);
            // const company = data.company;
            const user_id = data.user_id;
            // const sbwc = data.sbwc;
            $('.form-wrap-user-approve').show();
            $('.form-wrap-user-add').hide();
            $('.user-id').val(user_id);
          }
        }
      })
      .catch((err) => console.log(err));
  });

  $('#approve-user').on('click', function () {
    let company_sbwc = $('.company_sbwc').val();
    let company_id = $('.company_id').val();
    let user_id = $('.user-id').val();

    var formData = new FormData();

    formData.append('action', 'user_approve');
    formData.append('user_id', user_id);
    formData.append('company_id', company_id);
    formData.append('company_sbwc', company_sbwc);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
  });

  // $('#save_user').on('click', function () {
  //   let formArray = [];
  //
  //   let userForm = document.getElementById('create-new-user-form');
  //   let userFields = userForm.querySelectorAll('.new-user-item');
  //
  //   userFields.forEach(function (item) {
  //     let formItem = {};
  //     let fields = item.querySelectorAll('input');
  //     fields.forEach(function (field) {
  //       formItem[field.name] = field.value;
  //     });
  //     formArray.push(formItem);
  //   });
  //
  //   formArray = JSON.stringify(formArray);
  //   var formData = new FormData();
  //   formData.append('action', 'new_user');
  //   formData.append('new_user_data', formArray);
  //
  //   fetch(ajax_object.ajax_url, {
  //     method: 'POST',
  //     body: formData,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Error');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.success) {
  //         userForm.submit();
  //       } else {
  //         $('.new-user .error-alert').append(data.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // });

  // edit user
  $('#edit_users').on('click', function (el) {
    el.preventDefault();
    let formArray = [];

    let editForm = document.getElementById('edit-user-form');
    let userFields = editForm.querySelectorAll('.edit-user-item');

    userFields.forEach(function (item) {
      let formItem = {};
      let fields = item.querySelectorAll('input');
      fields.forEach(function (field) {
        formItem[field.name] = field.value;
      });
      formArray.push(formItem);
    });

    formArray = JSON.stringify(formArray);

    var formData = new FormData();
    formData.append('action', 'edit_users');
    formData.append('edit_user_data', formArray);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => editForm.submit())
      .catch((err) => console.log(err));
  });

  // List Headers
  var h2Tags = $('.page-template-default h2');

  var navigationList = $('<div class="nav-list-wrap"></div>');

  h2Tags.each(function (index) {
    var anchor = 'section-' + (index + 1);
    $(this).attr('id', anchor);

    var listItem = $(
      '<div class="nav-list-item"><a href="#' +
        anchor +
        '">' +
        $(this).text() +
        '</a></div>'
    );
    navigationList.append(listItem);
  });

  $('body .nav-list').prepend(navigationList);

  $('.nav-list a').on('click', function (e) {
    e.preventDefault();

    var targetId = $(this).attr('href');
    var targetElement = $(targetId);

    $('html, body .nav-list').animate(
      {
        scrollTop: targetElement.offset().top - 100,
      },
      1000
    );
  });

  //Phone number formaing
  $('.phone').on('input', function () {
    var phoneNumber = $(this).val();

    phoneNumber = phoneNumber.replace(/\D/g, '');

    var formattedPhoneNumber = '';
    for (var i = 0; i < phoneNumber.length; i++) {
      if ((i === 3 || i === 6) && i !== phoneNumber.length - 1) {
        formattedPhoneNumber += '-';
      }
      formattedPhoneNumber += phoneNumber[i];
    }

    $(this).val(formattedPhoneNumber);
  });

  $('.manage-buttons-delete').on('click', function () {
    let id = $(this).attr('data-file-id');
    let parent = $(this).parents('.manage-buttons-wrapp');
    let link = parent.find('.file-button');
    link.remove();
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'delete_file',
        id: id,
      },
    });
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});
/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

function generatePDF(link, name) {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';

  iframe.src = link;

  document.body.appendChild(iframe);

  iframe.onload = function () {
    html2pdf(iframe.contentDocument.body, {
      margin: 1,
      filename: name + '.pdf',
      image: { type: 'jpeg', quality: 1 },
      pagebreak: { mode: ['.nextpage', 'css', 'legacy'] },
      html2canvas: { scale: 1, scrollY: 0, scrollX: 0 },
    });

    document.body.removeChild(iframe);
  };
}

$('.download-previous-step').on('click', function (e) {
  e.preventDefault();
  let link = $(this).attr('href');
  let name = $(this).attr('data-step-name');
  generatePDF(link, name);
});
/* TODO More functionality for years */
$('.download-all').on('click', function (e) {
  e.preventDefault();
  var element = document.querySelector('.old-form-wrap');
  var CurrentYear = new Date().getFullYear();
  if (window.location.pathname === '/profile/previous/') {
    CurrentYear -= 1;
  }
  if ($(this).hasClass('admin-side')) {
    CurrentYear -= 1;
  }

  element.style.display = 'block';

  html2pdf(element, {
    margin: 10,
    filename: `GSIGTF_MIUF_${CurrentYear}.pdf`,
    image: { type: 'jpeg', quality: 1 },
    pagebreak: { mode: ['.nextpage', 'css', 'legacy'] },
    html2canvas: { scale: 1, scrollY: 0, scrollX: 0 },
  });

  setTimeout(() => {
    element.style.display = 'none';
  }, '300');
});

$('.admin-side').on('click', function (e) {
  console.log('test');
  e.preventDefault();
  var element = document.querySelector(
    '.prev-year-form-admin .old-form-wrap-test'
  );
  var CurrentYear = new Date().getFullYear();

  element.style.display = 'block';

  html2pdf(element, {
    margin: 10,
    filename: `GSIGTF_MIUF_${CurrentYear - 1}.pdf`,
    image: { type: 'jpeg', quality: 1 },
    pagebreak: { mode: ['.nextpage', 'css', 'legacy'] },
    html2canvas: { scale: 1, scrollY: 0, scrollX: 0 },
  });

  setTimeout(() => {
    element.style.display = 'none';
  }, '300');
});

// function saveSectionToPDF(sectionId) {
//   const section = document.querySelector(sectionId);
//
//   html2canvas(section, { scale: 2 })
//     .then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       const imgProps = pdf.getImageProperties(imgData);
//       const pdfWidth = pdf.internal.pageSize.getWidth();
//       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
//       pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
//       pdf.save('section.pdf');
//     })
//     .catch((error) => {
//       console.error('Error generating PDF:', error);
//     });
// }

// $('.download-all').on('click', function (e) {
//   e.preventDefault();
//   section = document.querySelector('.old-form-wrap');
//   section.style.display = 'block';
//   saveSectionToPDF('.old-form-wrap');
//   // const element = document.querySelector('.old-form-wrap');
//   //
//   // element.style.display = 'block';
//   //
//   // const pdf = new jsPDF();
//   //
//   // pdf.html(element, {
//   //   callback: function (pdf) {
//   //     // Сохраняем PDF файл
//   //     pdf.save('Form.pdf');
//   //   },
//   //   x: 10,
//   //   y: 10,
//   //   html2canvas: { scale: 1, scrollY: 0, scrollX: 0 },
//   // });
// });
